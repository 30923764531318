import React, { createContext, useEffect, useState } from 'react';

import {
  version as appVersion,
  type Version,
} from '@aster/app/core/shared/dtos/app';

import { useVersionQuery } from './queries/useVersionQuery';

import ConfirmationModal from '../../../components/ConfirmationModal';

const VersionContext = createContext<Version>('');

export const VersionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { version, isLoadingVersion } = useVersionQuery();

  const [isVersionModalOpen, setIsVersionModalOpen] = useState(false);

  useEffect(() => {
    if (isLoadingVersion) return;

    if (appVersion !== version) {
      setIsVersionModalOpen(true);
    }
  }, [version, isLoadingVersion]);

  const updateVersion = () =>
    fetch(window.location.href, {
      headers: {
        Pragma: 'no-cache',
        Expires: '-1',
        'Cache-Control': 'no-cache',
      },
    }).then(() => window.location.reload());

  return (
    <VersionContext.Provider value={version}>
      {children}
      <ConfirmationModal
        open={isVersionModalOpen}
        handleClose={() => setIsVersionModalOpen(false)}
        handleCancel={() => setIsVersionModalOpen(false)}
        handleConfirm={() => updateVersion()}
        title="New version available"
        description="A new version of Aster is available. Please update to continue."
        confirm="Update"
        dismiss="Dismiss"
      />
    </VersionContext.Provider>
  );
};
