import Typography from '../../../components/Typography';
import { CircularProgress } from '@mui/material';
import { Input } from '@aster/client/ui/Input/Input';
import { FormControl } from '@aster/client/ui/FormControl/FormControl';
import { Label } from '@aster/client/ui/Label/Label';
import { FormError } from '@aster/client/ui/FormControl/FormError';
import { Checkbox } from '@aster/client/ui/Checkbox/Checkbox';
import { useInsuranceInfoForm } from '../hooks/useInsuranceInfoForm';
import { isPatientReadonly } from '../utils/is-patient-readonly';
import { useDebouncedCallback } from 'use-debounce';
import { Profile, useAuth } from '../../../authentication/AuthProvider';
import { useUserUsagePreferences } from '@aster/shared/shared/client/utils';
import { LAST_SAVED_INSURANCE_INFORMATION } from '../constants';
import dayjs from 'dayjs';
import { ProfileSaveButton } from '../profileTabs/components/ProfileSaveButton';
import { forwardRef, useImperativeHandle, useState } from 'react';
import timeSinceLastSaved from '../utils/timeSinceLastSaved';
import { useProfileTabStore } from '../profileTabs/ProfileTab.store';
import { useShallow } from 'zustand/react/shallow';
import { PatientInfoDTO } from '@aster/app/core/shared/dtos/patient';
import { useStore } from '@tanstack/react-form';

const Insurance = forwardRef(
  (
    {
      updatePatientMutation,
      patientInfo,
      isPatientLoading,
    }: {
      patientInfo: PatientInfoDTO | undefined;
      updatePatientMutation: any;
      isPatientLoading: boolean;
    },
    ref
  ) => {
    const { setFormUnsavedStatus } = useProfileTabStore(
      useShallow((state) => ({
        setFormUnsavedStatus: state.setFormUnsavedStatus,
      }))
    );
    const [debounceValue, setDebounceValue] = useState<number>(2000);
    useImperativeHandle(ref, () => ({
      submit: async () => {
        setDebounceValue(0);
        await insuranceInfoForm.handleSubmit();
      },
    }));

    const { profile } = useAuth();
    const { storePreference, readPreference } = useUserUsagePreferences(
      profile as Profile
    );

    const lastSaved = readPreference<string>(LAST_SAVED_INSURANCE_INFORMATION);
    const lastSavedString = lastSaved ? timeSinceLastSaved(lastSaved) : '';

    const insuranceInfoForm = useInsuranceInfoForm({
      defaultValues: patientInfo as PatientInfoDTO,
      onSubmit: (value) => {
        return save(value);
      },
    });

    const save = useDebouncedCallback(async (value) => {
      await updatePatientMutation.mutateAsync(value);
      storePreference(LAST_SAVED_INSURANCE_INFORMATION, dayjs().toISOString());
      setDebounceValue(2000);
      insuranceInfoForm.reset(insuranceInfoForm.state.values, {
        keepDefaultValues: true,
      });
    }, debounceValue);

    useStore(insuranceInfoForm.store, ({ isDirty }) => {
      setFormUnsavedStatus('insurance', isDirty);
    });

    const readonly = isPatientReadonly(patientInfo);
    return (
      <>
        <ProfileSaveButton
          lastSavedString={lastSavedString}
          updatePatientMutation={updatePatientMutation}
          form={insuranceInfoForm}
        />
        <div className="pb-20 w-full container">
          {isPatientLoading ? (
            <CircularProgress />
          ) : (
            <form
              onSubmit={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                void insuranceInfoForm.handleSubmit();
              }}
            >
              <div className="flex h-fit">
                <div className="flex flex-col gap-y-5 w-full sm:w-auto">
                  <Typography
                    text="Insurance"
                    variant="h5"
                    customClass="font-semibold mb-5"
                  ></Typography>
                  <insuranceInfoForm.Field
                    name="cashOnly"
                    children={(field) => (
                      <FormControl className="relative flex flex-row gap-2">
                        <Checkbox
                          id={field.name}
                          disabled={readonly}
                          checked={field.state.value}
                          onCheckedChange={() => {
                            field.handleChange(!field.state.value);
                            void insuranceInfoForm.handleSubmit();
                          }}
                        />
                        <Label
                          htmlFor={field.name}
                          className="font-normal text-aster-secondary cursor-pointer"
                        >
                          Cash pay only
                        </Label>
                      </FormControl>
                    )}
                  />
                  <div className="flex flex-wrap gap-10">
                    <div className="flex flex-col gap-y-5 bg-gray-100 p-5 rounded-[10px] w-full sm:w-auto">
                      <Typography
                        text="Primary Insurance"
                        variant="h6"
                        customClass="font-semibold mb-5"
                      ></Typography>
                      <insuranceInfoForm.Field
                        name="insuranceFirstProvider"
                        children={(field) => (
                          <FormControl>
                            <Label>Insurance Provider</Label>
                            <Input
                              className="bg-white"
                              value={field.state.value}
                              onChange={(evt) =>
                                field.handleChange(evt.target.value)
                              }
                              onBlur={(evt) => {
                                if (field.state.meta.isDirty) {
                                  void insuranceInfoForm.handleSubmit();
                                }
                              }}
                              disabled={readonly}
                              errorMessageId={`err-${field.name}`}
                            />
                            <FormError id={`err-${field.name}`}>
                              {field.state.meta.errors?.join('\r')}
                            </FormError>
                          </FormControl>
                        )}
                      />
                      <insuranceInfoForm.Field
                        name="insuranceFirstNumber"
                        children={(field) => (
                          <FormControl>
                            <Label>Insurance Number</Label>
                            <Input
                              className="bg-white"
                              value={field.state.value}
                              onChange={(evt) =>
                                field.handleChange(evt.target.value)
                              }
                              onBlur={(evt) => {
                                if (field.state.meta.isDirty) {
                                  void insuranceInfoForm.handleSubmit();
                                }
                              }}
                              disabled={readonly}
                              errorMessageId={`err-${field.name}`}
                            />
                            <FormError id={`err-${field.name}`}>
                              {field.state.meta.errors?.join('\r')}
                            </FormError>
                          </FormControl>
                        )}
                      />
                      <insuranceInfoForm.Field
                        name="insuranceFirstGroupId"
                        children={(field) => (
                          <FormControl>
                            <Label>Insurance Group Id</Label>
                            <Input
                              className="bg-white"
                              value={field.state.value}
                              onChange={(evt) =>
                                field.handleChange(evt.target.value)
                              }
                              onBlur={(evt) => {
                                if (field.state.meta.isDirty) {
                                  void insuranceInfoForm.handleSubmit();
                                }
                              }}
                              disabled={readonly}
                              errorMessageId={`err-${field.name}`}
                            />
                            <FormError id={`err-${field.name}`}>
                              {field.state.meta.errors?.join('\r')}
                            </FormError>
                          </FormControl>
                        )}
                      />
                      <insuranceInfoForm.Field
                        name="useFirstForBaby"
                        children={(field) => (
                          <FormControl className="relative flex flex-row gap-2">
                            <Checkbox
                              id={field.name}
                              className="bg-white"
                              disabled={readonly}
                              checked={field.state.value}
                              onCheckedChange={() => {
                                field.handleChange(!field.state.value);
                                void insuranceInfoForm.handleSubmit();
                              }}
                            />
                            <Label
                              className="font-normal text-aster-secondary cursor-pointer"
                              htmlFor={field.name}
                            >
                              Apply this insurance to newborns
                            </Label>
                          </FormControl>
                        )}
                      />
                    </div>
                    <div className="flex flex-col gap-y-5 bg-gray-100 p-5 rounded-[10px] w-full sm:w-auto">
                      <Typography
                        text="Secondary Insurance"
                        variant="h6"
                        customClass="font-semibold mb-5"
                      ></Typography>
                      <insuranceInfoForm.Field
                        name="insuranceSecondProvider"
                        children={(field) => (
                          <FormControl>
                            <Label>Insurance Provider</Label>
                            <Input
                              className="bg-white"
                              value={field.state.value}
                              onChange={(evt) =>
                                field.handleChange(evt.target.value)
                              }
                              onBlur={(evt) => {
                                if (field.state.meta.isDirty) {
                                  void insuranceInfoForm.handleSubmit();
                                }
                              }}
                              disabled={readonly}
                              errorMessageId={`err-${field.name}`}
                            />
                            <FormError id={`err-${field.name}`}>
                              {field.state.meta.errors?.join('\r')}
                            </FormError>
                          </FormControl>
                        )}
                      />
                      <insuranceInfoForm.Field
                        name="insuranceSecondNumber"
                        children={(field) => (
                          <FormControl>
                            <Label>Insurance Number</Label>
                            <Input
                              className="bg-white"
                              value={field.state.value}
                              onChange={(evt) =>
                                field.handleChange(evt.target.value)
                              }
                              onBlur={(evt) => {
                                if (field.state.meta.isDirty) {
                                  void insuranceInfoForm.handleSubmit();
                                }
                              }}
                              disabled={readonly}
                              errorMessageId={`err-${field.name}`}
                            />
                            <FormError id={`err-${field.name}`}>
                              {field.state.meta.errors?.join('\r')}
                            </FormError>
                          </FormControl>
                        )}
                      />
                      <insuranceInfoForm.Field
                        name="insuranceSecondGroupId"
                        children={(field) => (
                          <FormControl>
                            <Label>Insurance Group Id</Label>
                            <Input
                              className="bg-white"
                              value={field.state.value}
                              onChange={(evt) =>
                                field.handleChange(evt.target.value)
                              }
                              onBlur={(evt) => {
                                if (field.state.meta.isDirty) {
                                  void insuranceInfoForm.handleSubmit();
                                }
                              }}
                              disabled={readonly}
                              errorMessageId={`err-${field.name}`}
                            />
                            <FormError id={`err-${field.name}`}>
                              {field.state.meta.errors?.join('\r')}
                            </FormError>
                          </FormControl>
                        )}
                      />
                      <insuranceInfoForm.Field
                        name="useSecondForBaby"
                        children={(field) => (
                          <FormControl className="relative flex flex-row gap-2">
                            <Checkbox
                              id={field.name}
                              className="bg-white"
                              disabled={readonly}
                              checked={field.state.value}
                              onCheckedChange={() => {
                                field.handleChange(!field.state.value);
                                void insuranceInfoForm.handleSubmit();
                              }}
                            />
                            <Label
                              className="font-normal text-aster-secondary cursor-pointer"
                              htmlFor={field.name}
                            >
                              Apply this insurance to newborns
                            </Label>
                          </FormControl>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </>
    );
  }
);

export default Insurance;
