import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { defaultRegularExerciseType } from '../defaults';
import { PatientInfoDTO } from '@aster/app/core/shared/dtos/patient';

export type LifestyleInfoFields = Pick<
  PatientInfoDTO,
  | 'usesTobacco'
  | 'tobaccoOrCigarettesPerDay'
  | 'consumesAlcohol'
  | 'alcoholUseDetails'
  | 'consumesRecreationalDrugs'
  | 'recreationalDrugUseDetails'
  | 'hasHealthyDiet'
  | 'hasDieataryRequirements'
  | 'dietaryRequirementsDetailsText'
  | 'practiceRegularExercise'
  | 'regularExerciseType'
  | 'regularExerciseHoursPerWeek'
>;

export const useLifestyleInfoForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: PatientInfoDTO | undefined;
  onSubmit: (value: Partial<LifestyleInfoFields>) => void;
}) => {
  const lifestyleInfoForm = useForm({
    defaultValues: {
      usesTobacco: defaultValues?.usesTobacco ?? null,
      tobaccoOrCigarettesPerDay:
        defaultValues?.tobaccoOrCigarettesPerDay ?? null,
      consumesAlcohol: defaultValues?.consumesAlcohol ?? null,
      alcoholUseDetails: defaultValues?.alcoholUseDetails ?? null,
      consumesRecreationalDrugs:
        defaultValues?.consumesRecreationalDrugs ?? null,
      recreationalDrugUseDetails:
        defaultValues?.recreationalDrugUseDetails ?? null,
      hasHealthyDiet: defaultValues?.hasHealthyDiet ?? null,
      hasDieataryRequirements: defaultValues?.hasDieataryRequirements ?? null,
      dietaryRequirementsDetailsText:
        defaultValues?.dietaryRequirementsDetailsText ?? null,
      practiceRegularExercise: defaultValues?.practiceRegularExercise ?? null,
      regularExerciseType: defaultRegularExerciseType.map((type) => ({
        ...type,
        checked:
          defaultValues?.regularExerciseType?.find(
            (item) => item.value === type.value
          )?.checked ?? false,
      })),
      regularExerciseHoursPerWeek:
        defaultValues?.regularExerciseHoursPerWeek ?? null,
    },
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      onSubmit(value);
    },
  });
  return lifestyleInfoForm;
};
