import { create } from 'zustand';

type FormName =
  | 'personal-information'
  | 'contact-information'
  | 'relationship'
  | 'insurance'
  | 'contact-list'
  | 'gynecologic-history'
  | 'pregnancy-information'
  | 'obstetric-history'
  | 'medical-history'
  | 'lifestyle'
  | 'social-information'
  | 'additional-information';

type ProfileTabStoreState = {
  unsavedForms: string[];
};

type ProfileTabStoreActions = {
  setFormUnsavedStatus: (form: FormName, needsSaving: boolean) => void;
};

type ProfileTabStore = ProfileTabStoreState & ProfileTabStoreActions;

export const useProfileTabStore = create<ProfileTabStore>((set) => ({
  // State
  unsavedForms: [],

  // Actions
  setFormUnsavedStatus: (form: FormName, needsSaving: boolean) => {
    set((state) => {
      if (needsSaving && !state.unsavedForms.includes(form)) {
        return {
          unsavedForms: [...state.unsavedForms, form],
        };
      } else if (!needsSaving && state.unsavedForms.includes(form)) {
        return {
          unsavedForms: state.unsavedForms.filter(
            (unsavedForm) => unsavedForm !== form
          ),
        };
      } else {
        return {};
      }
    });
  },
}));
