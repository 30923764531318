import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@aster/client/utils';
import { CircularSpinner } from '../CircularSpinner/CircularSpinner';

const buttonVariants = cva(
  'inline-flex items-center justify-center gap-2 whitespace-nowrap transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 group text-sm',
  {
    variants: {
      variant: {
        default:
          'bg-aster-main text-white font-medium hover:bg-aster-dark disabled:bg-gray-900',
        secondary:
          'bg-gray-100 text-gray-800 hover:bg-gray-200 disabled:bg-gray-200 font-medium',
        destructive: 'bg-red-500 text-white hover:bg-red-500/90 font-medium',
        outline:
          'border border-gray-300 bg-white text-aster-primary hover:border-gray-600',
        text: 'text-aster-secondary hover:text-primary',
        icon: 'bg-transparent text-aster-secondary hover:text-aster-primary',
        trigger:
          'border border-gray-300 text-aster-secondary bg-white text-aster-secondary focus:outline-none focus:border-gray-900 focus:shadow-elevation1 hover:border-gray-600',
        ghost: 'hover:bg-neutral-100 hover:text-neutral-900',
        link: 'decoration-gray-700 underline underline-offset-2 text-gray-700',
        'danger-link':
          'text-red-600 underline-offset-2 underline decoration-red-600 hover:text-red-800 hover:decoration-red-800',
      },
      size: {
        default: 'h-10 py-2 px-4 rounded-[6px]',
        trigger: 'p-2 rounded-md text-sm',
        sm: 'h-8 rounded px-3 py-2 text-xs',
        icon: 'size-10',
        link: 'p-0 text-sm',
        'link-xs': 'p-0 text-xs text-gray-500 decoration-gray-500',
        text: 'w-fit px-0',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      children,
      isLoading,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      >
        {isLoading && variant !== 'icon' && <CircularSpinner />}
        {children}
      </Comp>
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
