import { CircularProgress } from '@mui/material';
import Typography from '../../../components/Typography';
import {
  AdditionalInfoFields,
  useAdditionalInfoForm,
} from '../hooks/useAdditionalInfoForm';
import { FormControl } from '@aster/client/ui/FormControl/FormControl';
import { Label } from '@aster/client/ui/Label/Label';
import { isPatientReadonly } from '../utils/is-patient-readonly';
import { useDebouncedCallback } from 'use-debounce';
import dayjs from 'dayjs';
import { LAST_SAVED_ADDITIONAL_INFORMATION } from '../constants';
import { ProfileSaveButton } from '../profileTabs/components/ProfileSaveButton';
import { forwardRef, useImperativeHandle, useState } from 'react';
import timeSinceLastSaved from '../utils/timeSinceLastSaved';
import { Textarea } from '@aster/client/ui/Textarea/Textarea';
import { useProfileTabStore } from '../profileTabs/ProfileTab.store';
import { useShallow } from 'zustand/react/shallow';
import { PatientInfoDTO } from '@aster/app/core/shared/dtos/patient';
import { useStore } from '@tanstack/react-form';
import { Profile, useAuth } from '../../../authentication/AuthProvider';
import { useUserUsagePreferences } from '@aster/shared/shared/client/utils';

const AdditionalInformation = forwardRef(
  (
    {
      updatePatientMutation,
      patientInfo,
      isPatientLoading,
    }: {
      patientInfo: PatientInfoDTO | undefined;
      updatePatientMutation: any;
      isPatientLoading: boolean;
    },
    ref
  ) => {
    const { setFormUnsavedStatus } = useProfileTabStore(
      useShallow((state) => ({
        setFormUnsavedStatus: state.setFormUnsavedStatus,
      }))
    );

    const [debounceValue, setDebounceValue] = useState<number>(2000);
    useImperativeHandle(ref, () => ({
      submit: async () => {
        setDebounceValue(0);
        await additionalInfoForm.handleSubmit();
      },
    }));

    const { profile } = useAuth();
    const { storePreference, readPreference } = useUserUsagePreferences(
      profile as Profile
    );

    const lastSaved = readPreference<string>(LAST_SAVED_ADDITIONAL_INFORMATION);
    const lastSavedString = lastSaved ? timeSinceLastSaved(lastSaved) : '';

    const additionalInfoForm = useAdditionalInfoForm({
      defaultValues: patientInfo as PatientInfoDTO,
      onSubmit: (value) => {
        return save(value);
      },
    });

    useStore(additionalInfoForm.store, ({ isDirty }) => {
      setFormUnsavedStatus('additional-information', isDirty);
    });

    const save = useDebouncedCallback(
      async (value: Partial<AdditionalInfoFields>) => {
        await updatePatientMutation.mutateAsync(value);
        storePreference(
          LAST_SAVED_ADDITIONAL_INFORMATION,
          dayjs().toISOString()
        );
        setDebounceValue(2000);
        additionalInfoForm.reset(additionalInfoForm.state.values, {
          keepDefaultValues: true,
        });
      },
      debounceValue
    );

    const readonly = isPatientReadonly(patientInfo);
    return (
      <>
        <ProfileSaveButton
          lastSavedString={lastSavedString}
          updatePatientMutation={updatePatientMutation}
          form={additionalInfoForm}
        />
        <div className="pb-20 w-full container">
          {isPatientLoading ? (
            <CircularProgress />
          ) : (
            <form
              onSubmit={async (evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                void additionalInfoForm.handleSubmit();
              }}
            >
              <div className="flex h-fit">
                <div className="flex flex-col gap-y-5">
                  <Typography
                    text="Additional Information"
                    variant="h5"
                    customClass="font-semibold"
                  ></Typography>
                  <additionalInfoForm.Field
                    name="additionalInfo"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Anything else you would like us to know about your
                          pregnancy or medical history?
                        </Label>
                        <Textarea
                          name="additionalInfo"
                          placeholder="Additional information"
                          value={field.state.value}
                          onChange={(e) => field.handleChange(e.target.value)}
                          onBlur={async () => {
                            if (field.state.meta.isDirty) {
                              void additionalInfoForm.handleSubmit();
                            }
                          }}
                          disabled={readonly}
                        />
                      </FormControl>
                    )}
                  />
                </div>
              </div>
            </form>
          )}
        </div>
      </>
    );
  }
);

export default AdditionalInformation;
