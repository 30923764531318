import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ButtonType from './Button';
import { Button } from '@aster/client/ui/Button/Button';

type ModalProps = {
  title: string;
  description: string | React.ReactNode;
  confirm: string;
  dismiss: string;
  open: boolean;
  loading?: boolean;
  handleConfirm: any;
  secondaryConfirm?: string;
  handleSecondaryConfirm?: any;
  handleCancel?: () => void;
  handleClose: () => void;
};

const Modal = (props: ModalProps) => {
  const {
    open,
    title,
    description,
    confirm,
    dismiss,
    loading,
    handleClose,
    handleConfirm,
    handleCancel,
    secondaryConfirm,
    handleSecondaryConfirm,
  } = props;

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      className="z-[300]"
    >
      <div className="flex flex-col items-center">
        <DialogContent className="flex flex-col items-center">
          <p className="text-h4 font-semibold">{title}</p>
          <p className="mt-1">{description}</p>
        </DialogContent>
      </div>
      <DialogActions className="bg-grayBackground">
        <Button variant={'text'} onClick={handleCancel}>
          {dismiss}
        </Button>
        {secondaryConfirm && (
          <Button
            isLoading={loading}
            variant={'text'}
            onClick={handleSecondaryConfirm}
          >
            {secondaryConfirm}
          </Button>
        )}

        <Button isLoading={loading} variant={'default'} onClick={handleConfirm}>
          {confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
