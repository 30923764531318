import { cn } from '@aster/client/utils/cn';
import { HTMLMotionProps, motion } from 'motion/react';

export type NotificationBadgeProps = HTMLMotionProps<'span'> & {
  count: number;
};

const NotificationBadge = ({
  count,
  className,
  ...rest
}: NotificationBadgeProps) => {
  return (
    <motion.span
      className={cn(
        'bg-red-500 font-semibold p-[6px] text-[10px] text-white rounded-full leading-[10px]',
        className
      )}
      layout
      {...rest}
    >
      <span className="inline-flex justify-center min-w-[10px] leading-[10px]">
        {count}
      </span>
    </motion.span>
  );
};

export default NotificationBadge;
