import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { defaultNationalOrLocalPrograms } from '../defaults';
import { PatientInfoDTO } from '@aster/app/core/shared/dtos/patient';

export type SocialInfoFields = Pick<
  PatientInfoDTO,
  | 'hasSocialSupport'
  | 'employmentStatus'
  | 'doFeelSafe'
  | 'doFeelFinanciallySecure'
  | 'doHaveAdequateTransportation'
  | 'housingSituation'
  | 'doHaveEnoughHealthyFood'
  | 'lackOfHealthyFoodDetails'
  | 'nationalOrLocalPrograms'
  | 'doFeelSafeOnCurrentRelationship'
  | 'isPartnerControlling'
  | 'doHaveConcernsAboutChildSafety'
  | 'hasPartnerForcedSexually'
  | 'hasPartnerEverThreatenedOrMadeFeelUnsafe'
  | 'hasPreviousPartnerAbusiveExperience'
  | 'previousPartnerAbusiveExperienceDetails'
>;

export const useSocialInfoForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: PatientInfoDTO | undefined;
  onSubmit: (value: Partial<SocialInfoFields>) => void;
}) => {
  const socialInfoForm = useForm({
    defaultValues: {
      hasSocialSupport: defaultValues?.hasSocialSupport ?? null,
      employmentStatus: defaultValues?.employmentStatus ?? null,
      doFeelSafe: defaultValues?.doFeelSafe ?? null,
      doFeelFinanciallySecure: defaultValues?.doFeelFinanciallySecure ?? null,
      doHaveAdequateTransportation:
        defaultValues?.doHaveAdequateTransportation ?? null,
      housingSituation: defaultValues?.housingSituation ?? null,
      doHaveEnoughHealthyFood: defaultValues?.doHaveEnoughHealthyFood ?? null,
      lackOfHealthyFoodDetails: defaultValues?.lackOfHealthyFoodDetails ?? null,
      nationalOrLocalPrograms: defaultNationalOrLocalPrograms.map(
        (program) => ({
          ...program,
          checked:
            defaultValues?.nationalOrLocalPrograms?.find(
              (item) => item.value === program.value
            )?.checked ?? false,
        })
      ),
      doFeelSafeOnCurrentRelationship:
        defaultValues?.doFeelSafeOnCurrentRelationship ?? null,
      isPartnerControlling: defaultValues?.isPartnerControlling ?? null,
      doHaveConcernsAboutChildSafety:
        defaultValues?.doHaveConcernsAboutChildSafety ?? null,
      hasPartnerForcedSexually: defaultValues?.hasPartnerForcedSexually ?? null,
      hasPartnerEverThreatenedOrMadeFeelUnsafe:
        defaultValues?.hasPartnerEverThreatenedOrMadeFeelUnsafe ?? null,
      hasPreviousPartnerAbusiveExperience:
        defaultValues?.hasPreviousPartnerAbusiveExperience ?? null,
      previousPartnerAbusiveExperienceDetails:
        defaultValues?.previousPartnerAbusiveExperienceDetails ?? null,
    },
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      onSubmit(value);
    },
  });
  return socialInfoForm;
};
