import { PatientInfoDTO } from '@aster/app/core/shared/dtos/patient';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';

export type AdditionalInfoFields = Pick<PatientInfoDTO, 'additionalInfo'>;

export const useAdditionalInfoForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: PatientInfoDTO | undefined;
  onSubmit: (value: Partial<AdditionalInfoFields>) => void;
}) => {
  const additionalInfoForm = useForm({
    defaultValues: {
      additionalInfo: defaultValues?.additionalInfo ?? '',
    },
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      onSubmit(value);
    },
  });
  return additionalInfoForm;
};
