import { useQuery } from '@tanstack/react-query';

import { Version } from '@aster/app/core/shared/dtos/app';

import axios from '../../../axiosConfig';

const getVersion = async () => {
  const response = await axios.get<{ version: Version }>(
    // Setting a timestamp helps avoid caching issues with proxies
    // and CDNs, which can be more reliable than the Cache-Control
    // headers.
    `/v2/version?timestamp=${Date.now()}`
  );

  return response.data.version;
};

export const useVersionQuery = () => {
  const {
    data: version = '',
    isFetching: isLoadingVersion,
    ...rest
  } = useQuery({
    queryKey: ['version'],
    queryFn: getVersion,
    refetchInterval: 1000 * 60 * 5, // Every 5 minutes
  });
  return {
    version,
    isLoadingVersion,
    ...rest,
  };
};
