import { datadogRum } from '@datadog/browser-rum';

/**
 * Hook to store and read user usage preferences
 */
export function useUserUsagePreferences(profile: {
  id: string;
  practiceId: string;
}) {
  /**
   * Store a user usage preference in local storage
   *
   * @example
   * storePreference('patient-search-filters', {...})
   *
   */
  function storePreference(key: string, value: any) {
    if (!profile) return;
    localStorage.setItem(
      `${profile.practiceId}-${profile.id}-${key}`,
      JSON.stringify(value)
    );
  }

  /**
   * Read a user usage preference from local storage
   *
   * @example
   * const filters = readPreference<PatientSearchFilters>('patient-search-filters')
   */
  function readPreference<T>(key: string) {
    try {
      if (!profile) return;
      const raw = localStorage.getItem(
        `${profile.practiceId}-${profile.id}-${key}`
      );
      return raw ? (JSON.parse(raw) as T) : null;
    } catch (error) {
      datadogRum.addError(error);
      console.error('Error reading preference:', error);
      return null;
    }
  }

  return { storePreference, readPreference };
}
