import { cn } from '@aster/client/utils';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

export type CircularSpinnerProps = Omit<FontAwesomeIconProps, 'icon'>;

export function CircularSpinner({ className, ...rest }: CircularSpinnerProps) {
  return (
    <FontAwesomeIcon
      className={cn('animate-spin', className)}
      icon={faSpinnerThird}
      {...rest}
    />
  );
}
