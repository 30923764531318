import { cn } from '@aster/client/utils';
import * as React from 'react';

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          'flex min-h-[60px] w-full rounded-md border border-gray-300 bg-transparent p-3 text-sm shadow-sm placeholder:text-neutral-500 disabled:cursor-not-allowed disabled:opacity-50 bg-white',
          'hover:border-gray-600',
          'focus-visible:outline-none focus-visible:border-gray-900 focus-visible:shadow-elevation1',

          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Textarea.displayName = 'Textarea';

export { Textarea };
