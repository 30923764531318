import { useEffect, useMemo } from 'react';

import {
  faCircleCheck,
  faExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CircularSpinner } from '@aster/client/ui/CircularSpinner/CircularSpinner';

import AsterLogo from '../../assets/aster';
import Illustration from '../../assets/aster_baggu_illustration_black.webp';

import Typography from '../../components/Typography';

import { useAuth } from '../../authentication/AuthProvider';
import { useSubscriptionInformationQuery } from '../../integrations/stripe/queries/use-subscription-information';
import {
  LOADABLE_SUBSCRIPTION_STATUSES,
  SubscriptionStatus,
} from '@aster/app/core/shared/dtos/stripe';

export const Confirmation = () => {
  const { profile } = useAuth();

  const { subscriptionInformation, isLoading } =
    useSubscriptionInformationQuery(profile?.practiceId, 'confirmation', {
      enableRefetch: true,
    });

  const showLoadingState = useMemo(
    () =>
      isLoading ||
      LOADABLE_SUBSCRIPTION_STATUSES.includes(
        subscriptionInformation?.stripeSubscriptionStatus ?? ''
      ),
    [isLoading, subscriptionInformation?.stripeSubscriptionStatus]
  );

  useEffect(() => {
    // If the subscription state gets to a determinant state, we'll
    // cancel the polling and perofrm an full refresh, this way the
    // user gets back to the stripe provider and gets redirected to
    // the appropriate page.
    if (!showLoadingState) {
      setTimeout(() => {
        window.location.href = '/';
      }, 2500);
    }
  }, [showLoadingState, subscriptionInformation?.stripeSubscriptionStatus]);

  const icon = showLoadingState ? (
    <CircularSpinner className="text-aster-dark" />
  ) : subscriptionInformation?.stripeSubscriptionStatus ===
    SubscriptionStatus.active ? (
    <FontAwesomeIcon
      icon={faCircleCheck}
      size="2x"
      className="text-green-600"
    />
  ) : (
    <FontAwesomeIcon
      icon={faExclamationCircle}
      size="2x"
      className="text-red-600"
    />
  );

  const headline = showLoadingState
    ? 'Processing your payment...'
    : subscriptionInformation?.stripeSubscriptionStatus ===
      SubscriptionStatus.active
    ? 'Payment successful!'
    : 'Oops, something went wrong';

  const message = showLoadingState
    ? 'This may take a few moments.'
    : subscriptionInformation?.stripeSubscriptionStatus ===
      SubscriptionStatus.active
    ? "Thank you! We'll return you to Aster in just a moment."
    : 'Please fix the errors on the checkout screen.';

  return (
    <div className="w-full h-screen p-32 text-center bg-grayBackground relative">
      <img
        src={Illustration}
        className="absolute top-0 left-[calc(50%-512px)] min-w-[1024px] lg:left-0 lg:w-full h-auto opacity-[0.1] z-0 pointer-events-none user-select-none"
        alt="Aster Baggu Illustration Black"
      />
      <div className="relative z-20 inline-block mx-auto">
        <div className="mx-auto w-fit px-9 py-3 mb-10">
          <AsterLogo />
        </div>
        <div className="text-center space-y-6 bg-white p-8 rounded-[20px]">
          <div className="h-8">{icon}</div>
          <div className="space-y-4">
            <Typography variant="h1" customClass="text-2xl text-aster-neutral">
              {headline}
            </Typography>
            <Typography variant="bodyMedium" customClass="text-gray-500 mb-6">
              {message}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
