import { motion } from 'motion/react';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router';

import {
  faArrowLeftToLine,
  faArrowRightToLine,
} from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Divider from '@mui/material/Divider';

import { Typography } from '@aster/client/ui/Typography/Typography';
import { cn } from '@aster/client/utils/cn';

import Chip from './Chip';
import NotificationBadge from './StyledBadge';

import AsterLogo from '../assets/aster';
import Analytics from '../assets/icons/Analytics';
import Billing from '../assets/icons/Billing';
import Calendar from '../assets/icons/Calendar';
import Clients from '../assets/icons/Clients';
import Documents from '../assets/icons/Documents';
import EfaxIcon from '../assets/icons/EfaxIcon';
import Forms from '../assets/icons/Forms';
import House from '../assets/icons/House';
import Messages from '../assets/icons/Messages';
import Prescription from '../assets/icons/Prescription';
import Programs from '../assets/icons/Programs';
import Settings from '../assets/icons/Settings';
import SignOut from '../assets/icons/SignOut';
import Workflows from '../assets/icons/Workflows';
import { useAuth } from '../authentication/AuthProvider';
import { useDosespot } from '../features/dosespot/DosespotProvider';
import { useUnreadMessageThreadCountQuery } from '../features/messages/queries/use-unread-message-thread-count.query';

type MenuOptionProps = {
  link?: string;
  icon: React.ReactElement;
  title: string;
  className?: string;
  notificationCount?: number;
  notificationIndicator?: boolean;
  children?: React.ReactNode;
  active?: boolean;
  collapsed?: boolean;
  disabled?: boolean;
  badge?: 'new';
  activePaths?: RegExp[];
};

const SidebarOption = ({
  link,
  icon,
  title,
  active = false,
  collapsed = false,
  notificationCount = 0,
  notificationIndicator = false,
  badge,
}: MenuOptionProps) => {
  return (
    <Link to={link ?? '/'}>
      <motion.div
        className={cn(
          'flex no-underline items-center px-3 py-2  mb-2 w-[201px] relative cursor-pointer',
          {
            'hover:bg-white hover:bg-opacity-75': !active,
            'bg-white': active,
            'w-fit': collapsed,
          }
        )}
        style={{ borderRadius: 20 }}
        layout
      >
        <motion.span
          className="flex w-6 h-6 items-center justify-center scale-[1.125] [&>svg>path]:fill-primary"
          layout
        >
          {icon}
        </motion.span>
        {!collapsed && (
          <motion.span
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ bounce: 0 }}
            layout
          >
            <Typography
              variant="p-sm"
              className="ml-3 font-medium text-primary"
            >
              {title}
            </Typography>
          </motion.span>
        )}
        {badge && !collapsed && (
          <Chip
            label="New"
            className="bg-aster-main ml-1.5 h-4"
            classes={{
              label: 'font-[500] text-[9px] px-1.5 text-white',
            }}
          />
        )}
        {notificationCount > 0 && (
          <NotificationBadge
            className={cn('ml-1.5', {
              'm-0 absolute -top-1 -right-1': collapsed,
            })}
            count={notificationCount}
          />
        )}{' '}
        {notificationIndicator && (
          <motion.span
            className={cn('bg-violet-600 rounded-full w-2 h-2 ml-1.5', {
              'absolute top-1 right-1': collapsed,
            })}
            layout
          />
        )}
      </motion.div>
    </Link>
  );
};

const Sidebar = () => {
  const dosespotContext = useDosespot();

  const { isAsterAdmin } = useAuth();
  const { pathname } = useLocation();

  const [collapsed, setCollapsed] = useState(false);

  const { unreadMessageThreadCount } = useUnreadMessageThreadCountQuery();

  const sections: MenuOptionProps[][] = [
    [
      { link: '/', icon: <House />, title: 'Home' },
      ...(isAsterAdmin
        ? [
            {
              link: '/messages',
              icon: <Messages />,
              title: 'Messages',
              notificationIndicator: unreadMessageThreadCount > 0,
            },
          ]
        : []),
      { link: '/calendar', icon: <Calendar />, title: 'Calendar' },
      {
        link: '/patients',
        icon: <Clients />,
        title: 'Patients',
        activePaths: [/^\/patientProfile.*?/i, /^\/encounter.*?/i],
      },
      {
        link: '/prescription',
        icon: <Prescription />,
        title: 'Prescriptions',
        notificationCount: dosespotContext.dosespotData.notificationsCount,
      },
    ],
    [
      { link: '/forms', icon: <Forms />, title: 'Forms', disabled: true },
      { link: '/payments', icon: <Billing />, title: 'Payments' },
      { link: '/efax', icon: <EfaxIcon />, title: 'Fax' },
      {
        link: '/workflows',
        icon: <Workflows />,
        title: 'Workflow',
        disabled: true,
      },
      {
        link: '/programs',
        icon: <Programs />,
        title: 'Programs',
        disabled: true,
      },
    ],
    [
      { link: '/documents', icon: <Documents />, title: 'Documents' },
      {
        link: '/analytics',
        icon: <Analytics />,
        title: 'Analytics',
        disabled: true,
      },
      {
        link: '/settings',
        icon: <Settings />,
        title: 'Settings',
        disabled: true,
      },
    ],
    [{ link: '/signout', icon: <SignOut />, title: 'Sign out' }],
  ];

  const getActiveStatus = (option: MenuOptionProps, pathname: string) => {
    if (!option.link) return false;
    if (option.link === '/') return option.link === pathname;

    return (
      pathname.startsWith(option.link) ||
      option.activePaths?.some((expression) => expression.test(pathname))
    );
  };

  return (
    <motion.div
      className="z-50 bg-grayBackground px-3 py-5 border-r border-r-grayLight"
      id="sidebar"
      data-collapsed={collapsed}
      layout
    >
      <div className="flex justify-between mb-8">
        {!collapsed && (
          <motion.a
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ bounce: 0 }}
            layout
          >
            <Link to="/" className="flex items-center px-[17.15px]">
              <AsterLogo className="max-w-[92px]" />
            </Link>
          </motion.a>
        )}
        <motion.button
          onClick={() => setCollapsed((collapsed) => !collapsed)}
          className={cn('h-10 flex justify-center items-center px-2.5 ml-auto')}
          style={{ width: 44 }}
          layout
        >
          {collapsed ? (
            <FontAwesomeIcon icon={faArrowRightToLine} />
          ) : (
            <FontAwesomeIcon icon={faArrowLeftToLine} />
          )}
        </motion.button>
      </div>
      <nav className="items">
        {sections.map((section, i) => (
          <div className="mb-2" key={section[0].title}>
            {section
              .filter((section) => !section.disabled)
              .map((item) => (
                <SidebarOption
                  key={item.title}
                  {...item}
                  active={getActiveStatus(item, pathname)}
                  collapsed={collapsed}
                />
              ))}
            {i === sections.length - 2 && (
              <Divider className="border-grayLight" />
            )}
          </div>
        ))}
      </nav>
    </motion.div>
  );
};

export default Sidebar;
