import { useQuery } from '@tanstack/react-query';
import axios from '../../../app/axiosConfig';

const fetchUnreadMessageThreadCount = async () => {
  const response = await axios.get<{ count: number }>(
    `/v2/message/thread/unread`
  );
  return response.data;
};

export function useUnreadMessageThreadCountQuery() {
  const { data: count, isLoading } = useQuery({
    queryKey: ['unreadMessageThreadCount'],
    queryFn: fetchUnreadMessageThreadCount,
    refetchInterval: 5000,
  });

  return {
    unreadMessageThreadCount: count?.count ?? -1,
    isUnreadMessageThreadCountLoading: isLoading,
  };
}
