import { useParams } from 'react-router';
import PublicIntakeForm from '../patients/PublicIntakeForm';
import { useCallback, useState } from 'react';
import FormCompleted from './FormCompleted';
import { useValidateToken } from './queries/useValidateToken';
import LoadingGuestToken from './LoadingGuestToken';
import FormIllustration from '../../assets/icons/FormIllustration';
import { useCompleteFormMutation } from './mutations/complete-form.mutation';
import { FormType } from '@aster/app/core/shared/dtos/token';

function PublicForms() {
  const { token } = useParams();
  const [isComplete, setIsComplete] = useState(false);

  const { completeFormMutation } = useCompleteFormMutation({
    onSuccess: () => {
      setIsComplete(true);
    },
  });

  const onCompleteForm = useCallback(() => {
    completeFormMutation.mutate(token ?? '');
  }, [completeFormMutation, token]);

  const { tokenData, tokenIsValidating, tokenError } = useValidateToken(token);

  if (tokenIsValidating) {
    return (
      <LoadingGuestToken text="Just a moment, we're getting your intake form ready." />
    );
  }
  if (completeFormMutation.status === 'pending') {
    return (
      <LoadingGuestToken text="Just a moment, we're completing your intake form." />
    );
  }
  if (isComplete) {
    return (
      <FormCompleted
        text="Your form is completed. We will notify your practitioner."
        subtext="If you need to make any changes to your record, please contact your provider directly."
      />
    );
  }
  if (tokenError || completeFormMutation.isError) {
    return (
      <div className="h-full flex flex-col justify-center items-center">
        <div className="flex flex-col text-center items-center w-full min-w-min rounded-[20px] bg-white px-[35px] py-[30px] max-w-[480px]">
          <FormIllustration />
          <div className="text-h4 text-primary font-bold mb-4 mt-8 w-full">
            Hi there, this link has expired
          </div>
          <div className="text-body text-gray-500 text-center pb-5 sm:min-w-[55ch]">
            Intake form links expire after <strong>48 hours</strong> or if your
            provider <em>has sent out a new one</em>. We recommend checking your
            email for any new links or asking your provider to send the form
            again.
          </div>
        </div>
      </div>
    );
  }

  if (!tokenData?.metadata || !tokenData.metadata.formType) {
    return <p>invalid form token</p>;
  }

  switch (tokenData.metadata.formType as FormType) {
    case 'intake-form':
      return <PublicIntakeForm token={tokenData} onComplete={onCompleteForm} />;
    default:
      return <p>invalid form type</p>;
  }
}

export default PublicForms;
