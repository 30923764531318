import Typography from '../../../../components/Typography';
import dayjs from 'dayjs';
import {
  defaultOtherPregnancyOutcomes,
  defaultPregnancyInfo,
} from '../../defaults';
import { useObstetricHistoryInfoForm } from '../../hooks/useObstetricHistoryInfoForm';
import { Label } from '@aster/client/ui/Label/Label';
import { CircularProgress } from '@mui/material';
import { Button } from '@aster/client/ui/Button/Button';
import { useParams } from 'react-router';
import { isPatientReadonly } from '../../utils/is-patient-readonly';
import { useDebouncedCallback } from 'use-debounce';
import { LAST_SAVED_OBSTETRIC_INFORMATION } from '../../constants';
import { Profile, useAuth } from '../../../../authentication/AuthProvider';
import { useUserUsagePreferences } from '@aster/shared/shared/client/utils';
import { ProfileSaveButton } from '../../profileTabs/components/ProfileSaveButton';
import { forwardRef, Fragment, useImperativeHandle, useState } from 'react';
import timeSinceLastSaved from '../../utils/timeSinceLastSaved';
import Pregnancy from './Pregnancy';
import { useProfileTabStore } from '../../profileTabs/ProfileTab.store';
import { useShallow } from 'zustand/react/shallow';
import { PatientInfoDTO } from '@aster/app/core/shared/dtos/patient';
import { Token } from '@aster/app/core/shared/dtos/token';
import { useStore } from '@tanstack/react-form';

const ObstetricInformation = forwardRef(
  (
    {
      updatePatientMutation,
      patientInfo,
      isPatientLoading,
      token,
    }: {
      patientInfo: PatientInfoDTO | undefined;
      updatePatientMutation: any;
      isPatientLoading: boolean;
      token?: Token;
    },
    ref
  ) => {
    const { setFormUnsavedStatus } = useProfileTabStore(
      useShallow((state) => ({
        setFormUnsavedStatus: state.setFormUnsavedStatus,
      }))
    );
    const [debounceValue, setDebounceValue] = useState<number>(2000);
    useImperativeHandle(ref, () => ({
      submit: () => {
        setDebounceValue(0);
        void obstetricHistoryInfoForm.handleSubmit();
      },
    }));

    const { profile } = useAuth();
    const { storePreference, readPreference } = useUserUsagePreferences(
      profile as Profile
    );

    const lastSaved = readPreference<string>(LAST_SAVED_OBSTETRIC_INFORMATION);
    const lastSavedString = lastSaved ? timeSinceLastSaved(lastSaved) : '';

    const obstetricHistoryInfoForm = useObstetricHistoryInfoForm({
      defaultValues: patientInfo as PatientInfoDTO,
      onSubmit: async (value) => {
        const pregnancies = value?.map((item) => {
          return {
            ...item,
            pregnancy: {
              ...item.pregnancy,
              dateOfBirthOrEndOfPregnancy: item.pregnancy
                .dateOfBirthOrEndOfPregnancy
                ? dayjs(
                    item.pregnancy.dateOfBirthOrEndOfPregnancy
                  ).toISOString()
                : null,
            },
          };
        });
        await save({
          pregnancies,
        });
      },
    });

    useStore(obstetricHistoryInfoForm.store, ({ isDirty }) => {
      setFormUnsavedStatus('obstetric-history', isDirty);
    });

    const save = useDebouncedCallback(async (value) => {
      await updatePatientMutation.mutateAsync(value);
      storePreference(LAST_SAVED_OBSTETRIC_INFORMATION, dayjs().toISOString());
      setDebounceValue(2000);
      obstetricHistoryInfoForm.reset(obstetricHistoryInfoForm.state.values, {
        keepDefaultValues: true,
      });
    }, debounceValue);

    const readonly = isPatientReadonly(patientInfo);

    const { patient: patientID } = useParams<{ patient: string }>();

    return (
      <>
        <ProfileSaveButton
          lastSavedString={lastSavedString}
          updatePatientMutation={updatePatientMutation}
          form={obstetricHistoryInfoForm}
        />
        <div className="pb-20 w-full container">
          {isPatientLoading ? (
            <CircularProgress />
          ) : (
            <form
              onSubmit={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                void obstetricHistoryInfoForm.handleSubmit();
              }}
            >
              <div className="flex h-fit">
                <div className="flex flex-col gap-y-5">
                  <Typography
                    text="Obstetric History"
                    variant="h5"
                    customClass="font-semibold mb-5"
                  ></Typography>
                  {obstetricHistoryInfoForm.state.values.pregnancies.length ===
                    0 && (
                    <Label className="m-0">
                      Have you been pregnant before (not including current
                      pregnancy)?
                    </Label>
                  )}
                  <obstetricHistoryInfoForm.Field
                    name={'pregnancies'}
                    mode="array"
                  >
                    {(field) => {
                      return (
                        <Fragment key={field.state.value.length}>
                          {field.state.value.map((pregnancy, index) => (
                            <Fragment key={index}>
                              <Pregnancy
                                index={index}
                                field={field}
                                fieldValue={pregnancy}
                                obstetricHistoryInfoForm={
                                  obstetricHistoryInfoForm
                                }
                                readonly={readonly}
                              />
                            </Fragment>
                          ))}
                          <div>
                            <Button
                              size="default"
                              type="button"
                              disabled={readonly}
                              variant="secondary"
                              onClick={() => {
                                const newPregnancy = {
                                  pregnancy: {
                                    ...defaultPregnancyInfo.pregnancy,
                                    dateOfBirthOrEndOfPregnancy: undefined,
                                    patientID: patientID
                                      ? patientID
                                      : token?.metadata
                                      ? (token.metadata.patientID as string)
                                      : '',
                                    otherPregnancyOutcomes:
                                      defaultOtherPregnancyOutcomes,
                                  },
                                  children: defaultPregnancyInfo.children,
                                };
                                field.pushValue(newPregnancy);
                                void obstetricHistoryInfoForm.handleSubmit();
                              }}
                            >
                              + Add Pregnancy
                            </Button>
                          </div>
                        </Fragment>
                      );
                    }}
                  </obstetricHistoryInfoForm.Field>
                </div>
              </div>
            </form>
          )}
        </div>
      </>
    );
  }
);

export default ObstetricInformation;
