import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './firebase';

import { VersionProvider } from './providers/VersionProvider/VersionProvider';

import Router from '../Router';

import { AuthProvider } from '../authentication/AuthProvider';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { DosespotProvider } from '../features/dosespot/DosespotProvider';
import { FrontChatProvider } from '../integrations/front/providers/FrontChatProvider';
import { StripeProvider } from '../integrations/stripe/providers/StripeProvider';

function App() {
  const app = initializeApp(firebaseConfig);

  getAnalytics(app);

  return (
    <div className="flex h-screen w-full">
      <AuthProvider>
        <StripeProvider>
          <FrontChatProvider>
            <DosespotProvider>
              <VersionProvider>
                <Sidebar />
                <div className="flex flex-col w-full">
                  <Header />
                  <main className="w-full flex flex-col h-screen overflow-auto">
                    <Router />
                  </main>
                </div>
              </VersionProvider>
            </DosespotProvider>
          </FrontChatProvider>
        </StripeProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
