import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useObstetricHistoryInfoForm } from '../../hooks/useObstetricHistoryInfoForm';
import Typography from '../../../../components/Typography';
import { Button } from '@aster/client/ui/Button/Button';
import { FormControl } from '@aster/client/ui/FormControl/FormControl';
import { Label } from '@aster/client/ui/Label/Label';
import {
  RadioGroup,
  RadioIndicator,
  RadioItem,
} from '@aster/client/ui/Radio/Radio';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@aster/client/ui/Select/Select';
import { Input } from '@aster/client/ui/Input/Input';
import {
  defaultChildSexOptions,
  defaultFeedingCourseOptions,
} from '../../defaults';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { PregnancyField } from './Pregnancy';
import { ChildInfoDTO } from '@aster/app/core/shared/dtos/patient';

const Child = ({
  pregnancyIndex,
  childIndex,
  obstetricHistoryInfoForm,
  readonly,
  field,
  fieldValue,
}: {
  pregnancyIndex: number;
  childIndex: number;
  obstetricHistoryInfoForm: ReturnType<typeof useObstetricHistoryInfoForm>;
  readonly: boolean;
  field: PregnancyField;
  fieldValue: ReturnType<
    typeof useObstetricHistoryInfoForm
  >['state']['values']['pregnancies'][number];
}) => {
  return (
    <div
      key={`${pregnancyIndex}-${childIndex}`}
      className="flex flex-col gap-y-5 border-l-4 border-aster-main pl-6"
    >
      <div className="flex justify-between">
        <Typography customClass="my-3" variant={'h6'}>
          Child {childIndex + 1}
        </Typography>
        <Button
          type="button"
          variant="secondary"
          size={'default'}
          onClick={() => {
            const updatedPregnancies = [...field.state.value];
            updatedPregnancies[pregnancyIndex].children.splice(childIndex, 1);
            field.handleChange(updatedPregnancies);
            void obstetricHistoryInfoForm.handleSubmit();
          }}
          disabled={readonly || fieldValue.children.length === 1}
        >
          <FontAwesomeIcon icon={faTrash} />
          Remove
        </Button>
      </div>
      <obstetricHistoryInfoForm.Field
        name={`pregnancies[${pregnancyIndex}].children[${childIndex}].isLiving`}
      >
        {(subField) => (
          <FormControl>
            <Label className="mb-3">Is this child currently living?</Label>
            <RadioGroup
              className="flex flex-col items-start gap-3"
              value={
                subField.state.value === true
                  ? 'yes'
                  : subField.state.value === false
                  ? 'no'
                  : undefined
              }
              onValueChange={(value) => {
                const isLiving = value === 'yes' ? true : false;
                subField.handleChange(isLiving);
                void obstetricHistoryInfoForm.handleSubmit();
              }}
            >
              <div className="flex gap-2 items-center">
                <RadioItem
                  className="shrink-0"
                  id={`${subField.name}-yes`}
                  value={'yes'}
                  disabled={readonly}
                >
                  <RadioIndicator />
                </RadioItem>
                <Label
                  htmlFor={`${subField.name}-yes`}
                  className="m-0 cursor-pointer text-aster-secondary font-normal"
                >
                  Yes
                </Label>
              </div>
              <div className="flex gap-2 items-center">
                <RadioItem
                  className="shrink-0"
                  id={`${subField.name}-no`}
                  value={'no'}
                  disabled={readonly}
                >
                  <RadioIndicator />
                </RadioItem>
                <Label
                  htmlFor={`${subField.name}-no`}
                  className="m-0 cursor-pointer text-aster-secondary font-normal"
                >
                  No
                </Label>
              </div>
            </RadioGroup>
          </FormControl>
        )}
      </obstetricHistoryInfoForm.Field>
      <div className="flex flex-wrap gap-4">
        <obstetricHistoryInfoForm.Field
          name={`pregnancies[${pregnancyIndex}].children[${childIndex}].weightUnit`}
        >
          {(subField) => (
            <FormControl className="relative">
              <Label className="mb-2">Weight Unit</Label>
              <Select
                value={subField.state.value ?? ''}
                defaultValue={subField.state.value ?? ''}
                onValueChange={(value) => {
                  subField.handleChange(value as ChildInfoDTO['weightUnit']);
                  void obstetricHistoryInfoForm.handleSubmit();
                }}
                disabled={readonly}
              >
                <SelectTrigger className="bg-white">
                  <SelectValue placeholder={'Select Unit'} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="lbs">Lbs</SelectItem>
                    <SelectItem value="g">Grams</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>
          )}
        </obstetricHistoryInfoForm.Field>
        {obstetricHistoryInfoForm.state.values.pregnancies[pregnancyIndex]
          .children[childIndex].weightUnit === 'lbs' && (
          <div className="flex gap-4">
            <obstetricHistoryInfoForm.Field
              name={`pregnancies[${pregnancyIndex}].children[${childIndex}].pounds`}
            >
              {(subField) => (
                <FormControl>
                  <Label className="mb-2">Pounds</Label>
                  <Input
                    placeholder="Pounds"
                    type="number"
                    value={
                      subField.state.value === 0 ? '' : subField.state.value
                    }
                    onChange={(event) => {
                      const numberValue = Number(event.target.value);
                      if (numberValue > -1) {
                        subField.handleChange(numberValue);
                      }
                    }}
                    onBlur={() => {
                      if (subField.state.meta.isDirty) {
                        void obstetricHistoryInfoForm.handleSubmit();
                      }
                    }}
                    disabled={readonly}
                  />
                </FormControl>
              )}
            </obstetricHistoryInfoForm.Field>
            <obstetricHistoryInfoForm.Field
              name={`pregnancies[${pregnancyIndex}].children[${childIndex}].ounces`}
            >
              {(subField) => (
                <FormControl>
                  <Label className="mb-2">Ounces</Label>
                  <Input
                    placeholder="Ounces"
                    type="number"
                    value={
                      subField.state.value === 0 ? '' : subField.state.value
                    }
                    onChange={(event) => {
                      const numberValue = Number(event.target.value);
                      if (numberValue > -1) {
                        subField.handleChange(numberValue);
                      }
                    }}
                    onBlur={() => {
                      if (subField.state.meta.isDirty) {
                        void obstetricHistoryInfoForm.handleSubmit();
                      }
                    }}
                    disabled={readonly}
                  />
                </FormControl>
              )}
            </obstetricHistoryInfoForm.Field>
          </div>
        )}
        {obstetricHistoryInfoForm.state.values.pregnancies[pregnancyIndex]
          .children[childIndex].weightUnit === 'g' && (
          <obstetricHistoryInfoForm.Field
            name={`pregnancies[${pregnancyIndex}].children[${childIndex}].grams`}
          >
            {(subField) => (
              <FormControl>
                <Label className="mb-2">Grams</Label>
                <Input
                  placeholder="Grams"
                  type="number"
                  value={subField.state.value === 0 ? '' : subField.state.value}
                  onChange={(event) => {
                    const numberValue = Number(event.target.value);
                    if (numberValue > -1) {
                      subField.handleChange(numberValue);
                    }
                  }}
                  onBlur={() => {
                    if (subField.state.meta.isDirty) {
                      void obstetricHistoryInfoForm.handleSubmit();
                    }
                  }}
                  disabled={readonly}
                />
              </FormControl>
            )}
          </obstetricHistoryInfoForm.Field>
        )}
      </div>
      <obstetricHistoryInfoForm.Field
        name={`pregnancies[${pregnancyIndex}].children[${childIndex}].name`}
      >
        {(subField) => (
          <FormControl>
            <Label className="mb-2">Child's Name</Label>
            <Input
              placeholder="Name"
              value={subField.state.value}
              onChange={(event) => {
                subField.handleChange(event.target.value);
              }}
              onBlur={() => {
                if (subField.state.meta.isDirty) {
                  void obstetricHistoryInfoForm.handleSubmit();
                }
              }}
              disabled={readonly}
            />
          </FormControl>
        )}
      </obstetricHistoryInfoForm.Field>

      <obstetricHistoryInfoForm.Field
        name={`pregnancies[${pregnancyIndex}].children[${childIndex}].sex`}
      >
        {(subField) => (
          <FormControl>
            <Label className="mb-3">Child's Sex</Label>
            <RadioGroup
              className="flex flex-col items-start gap-3"
              value={subField.state.value as ChildInfoDTO['sex']}
              onValueChange={(value) => {
                subField.handleChange(value as ChildInfoDTO['sex']);
                void obstetricHistoryInfoForm.handleSubmit();
              }}
            >
              {defaultChildSexOptions.map((sex) => (
                <div key={sex.value} className="flex gap-2 items-center">
                  <RadioItem
                    className="shrink-0"
                    id={`${subField.name}-${sex.value}`}
                    value={sex.value}
                    disabled={readonly}
                  >
                    <RadioIndicator />
                  </RadioItem>
                  <Label
                    htmlFor={`${subField.name}-${sex.value}`}
                    className="m-0 cursor-pointer text-aster-secondary font-normal"
                  >
                    {sex.text}
                  </Label>
                </div>
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </obstetricHistoryInfoForm.Field>
      <obstetricHistoryInfoForm.Field
        name={`pregnancies[${pregnancyIndex}].children[${childIndex}].otherSex`}
      >
        {(subField) => (
          <FormControl>
            <Input
              placeholder="Other child sex details"
              value={subField.state.value}
              onChange={(event) => {
                subField.handleChange(event.target.value);
              }}
              onBlur={() => {
                if (subField.state.meta.isDirty) {
                  void obstetricHistoryInfoForm.handleSubmit();
                }
              }}
              disabled={readonly}
            />
          </FormControl>
        )}
      </obstetricHistoryInfoForm.Field>
      <obstetricHistoryInfoForm.Field
        name={`pregnancies[${pregnancyIndex}].children[${childIndex}].feedingCourse`}
      >
        {(subField) => (
          <FormControl>
            <Label className="mb-3">Feeding course</Label>
            <RadioGroup
              className="flex flex-col items-start gap-3"
              value={subField.state.value as ChildInfoDTO['feedingCourse']}
              onValueChange={(value) => {
                subField.handleChange(value as ChildInfoDTO['feedingCourse']);
                void obstetricHistoryInfoForm.handleSubmit();
              }}
            >
              {defaultFeedingCourseOptions.map((outcome) => (
                <div key={outcome.value} className="flex gap-2 items-center">
                  <RadioItem
                    className="shrink-0"
                    id={`${subField.name}-${outcome.value}`}
                    value={outcome.value}
                    disabled={readonly}
                  >
                    <RadioIndicator />
                  </RadioItem>
                  <Label
                    htmlFor={`${subField.name}-${outcome.value}`}
                    className="m-0 cursor-pointer text-aster-secondary font-normal"
                  >
                    {outcome.text}
                  </Label>
                </div>
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </obstetricHistoryInfoForm.Field>
      {obstetricHistoryInfoForm.state.values.pregnancies[pregnancyIndex]
        .children[childIndex].feedingCourse &&
        ['breast', 'both'].includes(
          obstetricHistoryInfoForm.state.values.pregnancies[pregnancyIndex]
            .children[childIndex].feedingCourse
        ) && (
          <obstetricHistoryInfoForm.Field
            name={`pregnancies[${pregnancyIndex}].children[${childIndex}].breastFeedDuration`}
          >
            {(subField) => (
              <FormControl>
                <Input
                  placeholder="Months of breastfeeding"
                  type="number"
                  value={subField.state.value === 0 ? '' : subField.state.value}
                  onChange={(event) => {
                    const numberValue = Number(event.target.value);
                    if (numberValue > -1) {
                      subField.handleChange(numberValue);
                    }
                  }}
                  onBlur={() => {
                    if (subField.state.meta.isDirty) {
                      void obstetricHistoryInfoForm.handleSubmit();
                    }
                  }}
                  disabled={readonly}
                />
              </FormControl>
            )}
          </obstetricHistoryInfoForm.Field>
        )}
      <obstetricHistoryInfoForm.Field
        name={`pregnancies[${pregnancyIndex}].children[${childIndex}].breastFeedDetails`}
      >
        {(subField) => (
          <FormControl>
            <Input
              placeholder="Other feeding details"
              value={subField.state.value}
              onChange={(event) => {
                subField.handleChange(event.target.value);
              }}
              onBlur={() => {
                if (subField.state.meta.isDirty) {
                  void obstetricHistoryInfoForm.handleSubmit();
                }
              }}
              disabled={readonly}
            />
          </FormControl>
        )}
      </obstetricHistoryInfoForm.Field>
    </div>
  );
};

export default Child;
