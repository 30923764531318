import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router';

import ErrorBoundary from '../../../components/ErrorBoundary';
import CheckoutPage from '../../../pages/Checkout';
import ConfirmationPage from '../../../pages/Confirmation';
import Error from '../../../pages/Error';

const INGORED_RETURN_TO_URLS = [
  '/',
  '/signin',
  '/signup',
  '/checkout',
  '/confirmation',
];

export const StripeRouter = () => {
  const ErrorWrapper = () => (
    <ErrorBoundary fallback={<Error type={500} />}>
      <Outlet />
    </ErrorBoundary>
  );

  const location = useLocation();
  const params = new URLSearchParams();

  if (!INGORED_RETURN_TO_URLS.includes(location.pathname)) {
    params.set('return_to', location.pathname);
  }

  const search = params.toString();

  return (
    <Routes>
      <Route element={<ErrorWrapper />}>
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/confirmation" element={<ConfirmationPage />} />
        <Route
          path="*"
          element={
            <Navigate to={`/checkout${search ? `?${search}` : ''}`} replace />
          }
        />
      </Route>
    </Routes>
  );
};
