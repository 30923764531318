export enum MutationScope {
  PATIENT = 'PATIENT',
  PRACTICE = 'PRACTICE',
  PATIENT_DOCUMENTS = 'PATIENT_DOCUMENTS',
  PRACTICE_DOCUMENTS = 'PRACTICE_DOCUMENTS',
  LABS_ORDER = 'LABS_ORDER',
  APPOINTMENT = 'APPOINTMENT',
  MEMBER = 'MEMBER',
  INTAKE_FORM = 'INTAKE_FORM',
  ENCOUNTER = 'ENCOUNTER',
  BILLING = 'BILLING',
  EFAX = 'EFAX',
  MESSAGE = 'MESSAGE',
}

export function createMutationScopeID(
  scope: MutationScope,
  id?: string | null
) {
  return `mutate-${scope}` + (id ? `-${id}` : '');
}
