import { useState } from 'react';
import { confirmSignUp, resendSignUpCode } from 'aws-amplify/auth';
import { useNavigate, useSearchParams } from 'react-router';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { useForm } from '@tanstack/react-form';
import { z } from 'zod';
import BasicTextfield from '../components/Textfield';
import ButtonType from '../components/Button';
import AsterLogo from '../assets/aster';
import Typography from '../components/Typography';
import { useSnackbar } from '../components/Snack';

const Verify = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const username =
    localStorage.getItem('user') ?? searchParams.get('username') ?? '';

  const { showMessage } = useSnackbar();

  const form = useForm({
    defaultValues: {
      code: '',
    },
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      setLoading(true);
      const { code } = value;
      try {
        await confirmSignUp({ username, confirmationCode: code });
        await navigate('/signin');
        localStorage.clear();
      } catch (err: any) {
        setError(err.message);
      }
      setLoading(false);
    },
  });

  const resendCode = async (event: any) => {
    event.preventDefault();
    if (username) {
      await resendSignUpCode({ username });
      showMessage({ message: 'Code has been resent!', type: 'success' });
    }
  };

  return (
    <div className="flex flex-col items-center h-screen w-screen pt-8 md:pt-32 bg-grayBackground">
      <div className="align-middle mb-10">
        <AsterLogo />
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
        className="flex flex-col items-center lg:w-1/3 sm:w-full px-9 py-7 rounded-[20px] bg-white max-w-[480px]"
      >
        <Typography text="Verify your email" variant="h4" />
        <Typography
          customClass="text-asterGray mt-1 mb-7"
          text="Enter the code sent to your email address"
          variant="bodyMedium"
        />
        <form.Field
          name="code"
          validators={{ onBlur: z.string().length(6, 'Invalid code') }}
          children={(field) => (
            <BasicTextfield
              id={field.name}
              variant="filled"
              label="Code"
              name={field.name}
              value={field.state.value}
              onChange={(evt) => field.handleChange(evt.target.value)}
              onBlur={field.handleBlur}
              error={!!error || field.state.meta.errors.length > 0}
              helperText={field.state.meta.errors.join('\r')}
              width="100%"
              classes="mt-5"
            />
          )}
        />
        {error && (
          <div className="grid grid-cols-6 w-full">
            <p className="text-red-500 !content-start col-span-5">{error}</p>
          </div>
        )}
        <ButtonType
          variant="contained"
          className="bg-primary mt-6 px-10 rounded-full text-white"
          disabled={loading}
          type="submit"
          text="Confirm"
        />
      </form>
      <div className="text-center sm:text-left bg-grayBackground my-4 sm:my-6">
        <ButtonType
          variant="text"
          text="Resend code"
          className="text-secondary"
          onClick={resendCode}
        />
      </div>
    </div>
  );
};

export default Verify;
